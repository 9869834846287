import { StyleSheet } from "react-native";
import { MAIN_COLOR, MAIN_COLORS, TAB_BAR_COLOR } from "../../../../Utility/Colors";
import { MAIN_FONT } from "../../../../Utility/Fonts/font";

var styles;

export default styles = StyleSheet.create({
  container: {
    gap: 22,
  },
  scenarioList: {
    maxHeight: 228,
    gap: 12,
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 6,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  wrapIcon: {
    backgroundColor: "#2E373F",
    borderRadius: "50%",
  },
  name: {
    fontFamily: MAIN_FONT.semi,
    fontSize: 14,
    fontWeight: 600,
    color: "#FFFFFF",
    width: 150,
  },
  time: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 10,
    fontWeight: 400,
    color: MAIN_COLORS.G600,
  },
  btnText: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: MAIN_FONT.regular,
    color: MAIN_COLORS.BACKGROUND_MAIN,
  },
  btnAdd: {
    paddingHorizontal: 24,
    paddingVertical: 10,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: MAIN_COLOR.PRIMARY_COLOR,
    gap: 6,
  },
});
