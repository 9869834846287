import React from "react";
import {
  Keyboard,
  Platform,
  RefreshControl,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { NavigationEvents } from "react-navigation";
import "@firebase/firestore";
import firebase from "firebase";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { user as setUser } from "../../Reducer/user";
import { setNotifications, setNotificationsObject } from "../../Reducer/tia";
import { MAIN_COLOR, MAIN_COLORS } from "../../Utility/Colors";
import LOADER from "../../Utility/ComponentCard/Loader";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import styles from "./stylev2";
import fields from "../../Utility/Fields";

import MainLayout from "../../Layout/Main";
import { stylesHome } from "../Home/components/index.v2";
import Time from "../../components/Time";
import CalendarCard from "../../Utility/ComponentCard/CalendarCard";
import EmptyContent from "../../components/EmptyContent";
import moment from "moment";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      today: new Date(),
      week: [],
      notifications: [],
      raw: [],
      tasks: [],
      email: null,
      refreshing: false,
      markedDates: this.props.markedDates,
      isLoading: false,
      day: new URLSearchParams(window.location.search).get("day"),
    };
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    const day = new URLSearchParams(window.location.search).get("day");
    if (!day && prevState.day) this.setState({ day: null });
  }

  aggregateByDate(obj) {
    const result = {};

    for (const key in obj) {
      const date = obj[key].date;

      if (result[date]) {
        result[date].quantity += 1;
      } else {
        result[date] = { ...obj[key], quantity: 1 };
      }
    }

    return result;
  }

  getMarkedDates(notifications) {
    let markedDates = {};
    notifications.map((item, index) => {
      markedDates[index] = {
        taskId: item.taskId,
        statusName: item.statusName,
        date: moment(item.dateUpdated.seconds * 1000).format("YYYY-MM-DD"),
      };
    });
    const result = this.aggregateByDate(markedDates);

    this.setState({ markedDates: result });
  }

  async refresh() {
    this.setState({ refreshing: true });
    this.setState({ isLoading: true });
    var db = firebase.firestore();
    let allLeadsId = "d22875df-8bfe-430f-8ab9-c33736223f47";
    let leads = [];
    if (this.props.TIA?.client?.custom_fields) {
      leads = this.props.TIA.client.custom_fields.filter(
        (item) => item.id === allLeadsId
      );
    }
    
    let leadsIds = {};
    if (leads.length > 0 && leads[0]?.value) {
      leads[0].value.map((item, index) => {
        leadsIds[item.id] = true;
      });
    }
    db.collection("notifications")
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.forEach((doc) => {
          if (leadsIds[doc.id]) {
            this.props.setNotificationsObject({ id: doc.id, data: doc.data() });
            arr = arr.concat(Object.values(doc.data()));
          }
        });

        arr.sort(function (a, b) {
          return (
            new Date(b.dateUpdated.seconds * 1000) -
            new Date(a.dateUpdated.seconds * 1000)
          );
        });
        this.setState({ refreshing: false });
        this.props.setNotifications(arr);
        this.getMarkedDates(arr);
      })
      .catch((error) => {
        alert(error.message);
        this.setState({ refreshing: false });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  componentDidMount() {
    this.refresh();
  }

  getStatusName(statusName) {
    let keys = Object.keys(this.props.TIA.template);
    let index = keys.indexOf(statusName);

    return index > 0
      ? this.props.TIA.template[keys[index - 1]][fields.appStageName]
      : this.props.TIA.template[keys[index]][fields.appStageName];
  }

  render() {
    const { email, refreshing, markedDates, day } = this.state;

    const opportunities = this.props.TIA.opportunities || [];
    let notifications = this.props.TIA.notifications || [];

    notifications = notifications.map((noti) => {
      const opportunityFound = opportunities.find(
        (opportunity) => opportunity.id === noti.taskId
      );

      const image = opportunityFound?.purchase_connected_image;
      const isConnected = opportunityFound?.["Proposed Property"]?.[0];

      return { ...noti, image: image, isConnected };
    });

    notifications = day
      ? notifications.filter((item) => {
          if (
            day ===
            moment(item.dateUpdated.seconds * 1000)
              .format("YYYY-MM-DD")
              .toString()
          )
            return item;
        })
      : notifications;

    return (
      <>
        <MainLayout
          childrenHeader={
            <View
              style={[
                stylesHome.header,
                {
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                },
              ]}
            >
              {day && (
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ day: null });
                    window.history.replaceState(
                      null,
                      "",
                      window.location.pathname
                    );
                  }}
                >
                  <Image
                    style={{
                      width: 30,
                      height: 30,
                    }}
                    source={require("../../assets/home/back.svg")}
                  />
                </TouchableOpacity>
              )}
              <Text style={[stylesHome.headerTitle, { fontSize: 20 }]}>
                Notifications
              </Text>
              {day && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <Text style={stylesHome.headerTitle}> /</Text>
                  <Text
                    style={[
                      stylesHome.headerTitle,
                      { fontSize: 20, color: MAIN_COLORS.PRIMARY_COLOR },
                    ]}
                  >
                    {moment(day).format("DD MMMM YYYY")}
                  </Text>
                </View>
              )}
            </View>
          }
          childrenMenu={
            <View style={stylesHome.contentMenu}>
              <CalendarCard
                markedDates={markedDates}
                initialDate={day}
                onPressDay={(day) => {
                  if (day !== this.state.day) {
                    window.history.replaceState({}, "", `?day=${day}`);
                    this.setState({ day: day });
                  }
                }}
              />
            </View>
          }
        >
          <SafeAreaView
            style={{ flex: 1, backgroundColor: MAIN_COLORS.BACKGROUND_BLACK }}
            keyboardShouldPersistTaps={true}
            behavior={Platform.OS === "ios" ? "padding" : "padding"}
            keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}
          >
            <NavigationEvents
              onWillFocus={this._onFocus}
              onWillBlur={this._onBlurr}
            />

            <ScrollView
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={() => this.refresh(email)}
                />
              }
              onPress={Keyboard.dismiss}
              accessible={false}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                flexGrow: 1,
                width: "100%",
                height: "100%",
              }}
            >
              <View style={styles.scrollViewContentView}>
                <View style={styles.centerView}>
                  {
                    notifications?.length
                      ? notifications.map((item, index) => {
                          return (
                            <View key={index}>
                              <TouchableOpacity
                                onPress={() => {
                                  let keys = Object.keys(
                                    this.props.TIA.template
                                  );
                                  let index = keys.indexOf(item.statusName);
                                  const _statusName =
                                    index === 0 ? keys[index] : keys[index - 1];

                                  this.props.goToProjectDetails(
                                    item.taskId,
                                    _statusName
                                  );
                                }}
                                activeOpacity={0.8}
                                style={{
                                  backgroundColor:
                                    MAIN_COLOR.BACKGROUND_COLOR_MAIN,
                                  borderRadius: 10,
                                  padding: 12,
                                  marginBottom: 12,
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <View style={{ flexDirection: "row" }}>
                                    <View
                                      style={{
                                        width: 64,
                                        height: 64,
                                        borderRadius: 8,
                                        marginRight: 12,
                                        overflow: "hidden",
                                      }}
                                    >
                                      <Image
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          transform: [
                                            {
                                              scale: item?.isConnected ? 2 : 1,
                                            },
                                          ],
                                        }}
                                        source={
                                          item?.image
                                            ? { uri: item?.image }
                                            : item?.isConnected
                                            ? require("../../assets/home/portfolio_image_default.png")
                                            : require("../../assets/home/no_available.svg")
                                        }
                                      />
                                    </View>
                                    <View style={{ flexDirection: "column" }}>
                                      <Text
                                        style={{
                                          fontSize: 14,
                                          color: "#FFFFFF",
                                          fontFamily: MAIN_FONT.semi,
                                          marginBottom: 4,
                                        }}
                                      >
                                        {this.getStatusName(item.statusName)}
                                      </Text>
                                      <Text
                                        style={{
                                          fontSize: 12,
                                          color: "#A3A3A3",
                                          fontFamily: MAIN_FONT.regular,
                                          marginBottom: 4,
                                        }}
                                      >
                                        {item.taskName}
                                      </Text>
                                      <Time
                                        date={item.dateUpdated.seconds * 1000}
                                      />
                                    </View>
                                  </View>

                                  <View style={{ flexDirection: "row" }}>
                                    <TouchableOpacity
                                      style={{
                                        flexDirection: "row",
                                        flex: 1,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 77,
                                        padding: 4,
                                        width: 32,
                                        height: 32,
                                      }}
                                    >
                                      <Image
                                        style={{
                                          resizeMode: "contain",
                                          width: 18,
                                          height: 18,
                                        }}
                                        source={require("../../assets/three-dots.svg")}
                                      />
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                          );
                        })
                      : ""
                    // <EmptyContent
                    //   title="Content"
                    //   subTitle="Please click on your assets under Purchases for details."
                    // />
                  }
                </View>
              </View>
            </ScrollView>
          </SafeAreaView>
        </MainLayout>
        {this.state.isLoading ? <LOADER /> : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setNotifications,
      setNotificationsObject,
      setUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
