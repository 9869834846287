import React, { memo, useEffect, useImperativeHandle, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import * as ImagePicker from "expo-image-picker";
import { yupResolver } from "@hookform/resolvers/yup";
import { MAIN_COLORS } from "../../Utility/Colors";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { BlurView } from "expo-blur";
import { editProfileUser, getProfileAccount } from "../../Clickup/api";
import { isEqual, omitBy } from "lodash";
import { setClient } from "../../Reducer/tia";
import ModalBase from "../../components/Modal";
import { showMessage } from "react-native-flash-message";
import TextInputComp from "../../components/TextInput";

const schema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone: yup
    .string()
    .nullable()
    .notRequired()
    .test("is-valid-phone", "Invalid phone number", (value) => {
      if (!value || value.trim() === "+61") return true;
      return /^(\+61\s3\s\d{4}\s\d{4}|\(0[3]\)\s\d{4}\s\d{4})$/.test(value);
    }),
  email: yup.string().email("Invalid email"),
});

const UserProfile = ({ onClose, open }) => {
  const { client } = useSelector((state) => state.TIA);
  const [focusedInput, setFocusedInput] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleProfilePictureChange = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.cancelled) {
      setProfilePicture(result.uri);
    }
  };

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  const {
    reset,
    getValues,
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {},
  });

  const handleEdit = async (data) => {
    try {
      setLoading(true);
      const datafilterd = omitBy(
        data,
        (value, key) => isEqual(value, client[key]) || !value || (key === "phone" && value === "+61 "),
      );

      const result = await editProfileUser(datafilterd, client?.id);
      if (result.status !== 200) {
        await onClose();
        return showMessage({
          message: result?.exception?.detail || "Update profile failed!",
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
      }

      await new Promise((resolve) => {
        setTimeout(async () => {
          const infoUser = await getProfileAccount(client?.id);

          if (infoUser?.status !== 200) {
            await onClose();
            return showMessage({
              message: infoUser?.exception?.detail || "Get profile failed!",
              type: "info",
              color: "#FFF",
              backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
              duration: 4000,
            });
          } else {
            const { user_info } = infoUser.data;
            const {email, ...pass_info} = user_info
            dispatch(setClient({ ...client, ...pass_info }));
            await onClose();
            showMessage({
              message: "Update profile successfully",
              type: "info",
              color: "#FFF",
              backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
              duration: 4000,
            });
          }
          resolve([]);
        }, 1000);
      });
    } catch (error) {
    } finally {
      setLoading(false);
      handleReset();
    }
  };

  const renderTextInput = ({
    field: { onChange, onBlur, value },
    placeholder,
    name,
    icon,
    editable = true,
    pointerEvents,
    styleInput,
    styleInputForm,
  }) => (
    <View
      style={[
        styles.inputForm,
        styles.rowCenter,
        styleInputForm,
        {
          borderColor: focusedInput === name ? "#fff" : "#34393D",
        },
      ]}
      pointerEvents={pointerEvents}
    >
      <TextInput
        autoCorrect={false}
        keyboardType="default"
        returnKeyType={"done"}
        value={value}
        style={[styles.inputPassword, styleInput]}
        placeholderTextColor="#ADB9C7"
        placeholder={placeholder}
        underlineColorAndroid="transparent"
        onBlur={() => {
          setFocusedInput(null);
          onBlur();
        }}
        onFocus={() => setFocusedInput(name)}
        onChangeText={onChange}
        editable={editable}
      />
      {icon && (
        <Image
          style={{
            height: 20,
            width: 20,
            resizeMode: "contain",
            marginLeft: 8,
          }}
          source={icon}
        />
      )}
    </View>
  );

  const handleFormatPhone = (value) => {
    let formattedText = value;

    if (getValues().phone.length > value.length) {
      if (!formattedText?.startsWith("+61")) formattedText = getValues().phone;
      else formattedText = formattedText.trim();
    } else {
      if (/^[1-9]$/.test(value[0]) || value[0] === "+") {
        formattedText = formattedText.replace(/[^0-9+]/g, "");

        if (formattedText.length === 0) {
          formattedText = "";
        } else if (!formattedText?.startsWith("+61")) {
          formattedText = "+61" + formattedText.replace(/^0/, "");
        }

        if (formattedText.length > 3) {
          formattedText = formattedText.replace(
            /(\+61)(\d{1})(\d{0,4})(\d{0,4})/,
            "$1 $2 $3 $4"
          );
        }
      }
    }

    return formattedText === "+61" ? "+61 " : formattedText.trim();
  };

  const handleReset = () => {
    reset({
      phone: client?.phone_number || "",
      first_name: client?.first_name || "",
      last_name: client?.last_name || "",
      email: client?.email || "+61 ",
    });
  };

  useEffect(() => {
    handleReset();
  }, [client]);

  return (
    <ModalBase
      open={open}
      btnSave="Save"
      btnCancel="Cancel"
      disableSave={!isValid || !isDirty || loading}
      title="User Profile"
      onSave={handleSubmit(handleEdit)}
      onClose={() => {
        onClose();
        handleReset();
      }}
      size="m-large"
      styleModal={{ padding: 18, borderRadius: 12 }}
      styleBtnClose={{
        width: 28,
        height: 28,
        borderRadius: 5,
        padding: 4,
        backgroundColor: "#7D888F26",
      }}
      sizeBtnClose={{ width: 10, height: 10 }}
      closePrimary={true}
    >
      <View>
        {/* <View style={styles.profilePictureContainer}>
          <TouchableOpacity onPress={handleProfilePictureChange}>
            <View style={styles.profilePicture}>
              <BlurView intensity={5} style={styles.blurPicture}>
                <Image
                  style={{ width: 24, height: 24 }}
                  source={require("../../assets/gallery-edit.svg")}
                />
              </BlurView>
              {profilePicture ? (
                <Image
                  source={{ uri: profilePicture }}
                  style={styles.profileImage}
                />
              ) : (
                <Text style={styles.placeholderText}>
                  {getInitial(client?.first_name)}
                </Text>
              )}
            </View>
          </TouchableOpacity>
        </View> */}

        <View style={styles.sectionForm}>
          <Text style={styles.labelForm}>First Name</Text>
          <Controller
            name="first_name"
            control={control}
            render={(props) =>
              renderTextInput({
                ...props,
                placeholder: "Enter your First Name",
                name: "first_name",
              })
            }
          />
        </View>

        <View style={styles.sectionForm}>
          <Text style={styles.labelForm}>Last Name</Text>
          <Controller
            name="last_name"
            control={control}
            render={(props) =>
              renderTextInput({
                ...props,
                placeholder: "Enter your Last Name",
                name: "last_name",
              })
            }
          />
        </View>

        <View style={styles.sectionForm}>
          <Controller
            name="phone"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInputComp
                value={value}
                onChangeText={(value) => {
                  const formattedText = handleFormatPhone(value);
                  onChange(formattedText);
                }}
                label="Phone"
                maxLength={value?.[0] === "+" ? 15 : 14}
              />
            )}
          />
        </View>

        <View style={styles.sectionForm}>
          <Text style={styles.labelForm}>Email</Text>
          <Controller
            name="email"
            control={control}
            render={(props) =>
              renderTextInput({
                ...props,
                placeholder: "Enter your email",
                name: "email",
                icon: require("../../assets/disable-email.svg"),
                editable: false,
                pointerEvents: "none",
                styleInput: { color: MAIN_COLORS.G600 },
                styleInputForm: { backgroundColor: "#253039" },
              })
            }
          />
        </View>
        <Text
          style={[
            styles.inputPassword,
            { color: MAIN_COLORS.TEXT_GRAY, fontSize: 16 },
          ]}
        >
          {'*Phone number must be in the form "+61 3 xxxx xxxx"'}
        </Text>
      </View>
    </ModalBase>
  );
};

const styles = StyleSheet.create({
  sectionForm: {
    marginBottom: 16,
  },
  labelForm: {
    lineHeight: 21,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: "#FFFFFF",
    marginBottom: 8,
  },
  inputForm: {
    height: 46,
    width: "100%",
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 10,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    outlineStyle: "none",
  },
  rowCenter: {
    display: "flex",
    alignItems: "center",
  },
  inputPassword: {
    flex: 1,
    color: MAIN_COLORS.TEXT_LIGHT,
    outlineStyle: "none",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 21,
  },
  errorText: {
    color: "red",
    fontSize: 12,
    marginTop: 4,
  },
  submitButton: {
    backgroundColor: MAIN_COLORS.PRIMARY,
    padding: 12,
    borderRadius: 6,
    alignItems: "center",
  },
  submitButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  profilePictureContainer: {
    alignItems: "center",
    marginBottom: 16,
  },
  profilePicture: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  blurPicture: {
    width: "100%",
    height: "35%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    zIndex: 999,
  },
  profileImage: {
    width: "100%",
    height: "100%",
    borderRadius: 50,
  },
  placeholderText: {
    fontSize: 36,
    color: MAIN_COLORS.BACKGROUND_MAIN,
    fontWeight: 600,
    fontFamily: MAIN_FONT.semi,
  },
});

export default memo(UserProfile);
