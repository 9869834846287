import {
  View,
  Text,
  Image,
  Linking,
  Keyboard,
  Platform,
  TextInput,
  Dimensions,
  BackHandler,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showMessage } from "react-native-flash-message";
import { RFValue } from "react-native-responsive-fontsize";
import { MaterialIndicator } from "react-native-indicators";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import jwtDecode from "jwt-decode";

//Function
import styles from "../Auth/style";
import { MAIN_COLORS } from "../../Utility/Colors";
import {
  addToken,
  signIn,
  signInAccount,
  signInWithFacebook,
  signInWithGoogle,
} from "../../Clickup/api";
import { reset, setClient } from "../../Reducer/tia";

//Component
import AuthBanner from "../Auth/AuthBanner";
import ReCAPTCHA from "react-google-recaptcha";
import LOADER from "../../Utility/ComponentCard/Loader";
import stylesCommonComponents from "../../Utility/ComponentStyle/style";
import { MAIN_FONT } from "../../Utility/Fonts/font";
import { FACEBOOK_CLIENT_ID, GOOGLE_CLIENT_ID } from "../../Utility/common";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoading: false,
      signing: false,
      popupTypeId: 0,
      responseToken: null,
      showPassword: false,
      isRememberPassword: true,
      isFocus: null,
      token: { data: "" },
      popupIconName: "",
      popupButtonText: "",
      popupMessageText: "",
      popupMessageTitle: "",
      showPopupMessageBox: false,
      window: Dimensions.get("window"),
      isPC: Dimensions.get("window").width > 1024 ? true : false,
      isSmall: Dimensions.get("window").height < 900 ? true : false,
      type: props.navigation.state.params?.type || "",
    };
  }

  _onBlurr = () => {
    BackHandler.removeEventListener(
      "hardwareBackPress",
      this._handleBackButtonClick
    );
  };

  _onFocus = () => {
    BackHandler.addEventListener(
      "hardwareBackPress",
      this._handleBackButtonClick
    );
  };

  _handleBackButtonClick = () => {
    BackHandler.exitApp();
    return true;
  };

  componentWillUnmount() {}

  componentDidMount() {
    Dimensions.addEventListener("change", ({ window }) => {
      this.setState({
        isPC: window.width > 1000 ? true : false,
        isSmall: window.height < 900 ? true : false,
      });
    });

    AsyncStorage.getItem("emailAccount").then((savedEmail) => {
      if (savedEmail) {
        this.setState({ email: savedEmail });
      }
    });

    AsyncStorage.getItem("type").then((typeScreen) => {
      if (typeScreen) {
        this.setState({ type: typeScreen }, () => {
          if (this.state.type === "forgot")
            this.props.navigation.navigate("ForgotPasswordScreen");
          else if (this.state.type === "create")
            this.props.navigation.navigate("RegisterScreen");
          else this.props.navigation.navigate("LoginScreen");
        });
      }
    });

    this.props.reset({});

    Linking.getInitialURL()
      .then((url) => {
        if (
          url.includes(
            "https://theinvestorsagency.com.au/client_portal/?resetPassword?email="
          )
        ) {
          url = url.replace(
            "https://theinvestorsagency.com.au/client_portal/?resetPassword?email=",
            ""
          );
          this.props.navigation.navigate("ResetPasswordScreen", { email: url });
        }
      })
      .catch(console.warn);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (window.location.pathname === "/verify-email" && params.get("email"))
      this.props.navigation.navigate("SignUpScreen");
  }

  onResponseTokenReceived = async () => {
    const { email, password, token, responseToken } = this.state;

    this.setState({ signing: true });

    signIn(email, password, responseToken)
      .then((result) => {
        if (result.tasks?.length) {
          const r = result.tasks[0].custom_fields.filter(
            (item, index) => item.id === "08208191-53bd-4985-97f1-2dd9b9327797"
          );
          if (r[0].value !== password) {
            showMessage({
              message: "Email or Password is incorrect.",
              type: "info",
              color: "#FFF",
              backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
              duration: 4000,
            });
            this.setState({ signing: false });
            return;
          }
          this.props.setClient({ ...result.tasks[0], email: email });
          this.props.navigation.navigate("MainScreen");
          addToken(result.tasks[0], token.data);
        } else {
          showMessage({
            message: "Something went wrong, please try again later.",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            duration: 4000,
          });
          this.setState({ signing: false });
        }
      })
      .catch((error) => {
        showMessage({
          message: error.message,
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
        this.setState({ signing: false });
      });
  };

  onLogin = async (response, type) => {
    try {
      let result, messageError;
      this.setState({ isLoading: true });
      this.setState({ signing: true });

      if (type === "facebook") {
        if (response?.status === "unknown") return;

        const body = {
          email: response.email,
          password: "",
          auth_provider: "facebook",
          auth_token: response.accessToken,
          is_login: true,
        };
        result = await signInWithFacebook(body);
        if (result?.status !== 200) {
          messageError =
            result?.status === 400
              ? 'This Facebook account is already associated with an existing account in TIA app.'
              : result?.exception || 'An error occurred during Google sign-in.';
        }
      } else if (type === "google") {
        if (!response?.credential) return;

        const id_token = response?.credential;
        const decodedJWT = jwtDecode(id_token);

        if (!decodedJWT) return;

        const body = {
          email: decodedJWT?.email,
          password: "",
          auth_provider: "google",
          auth_token: id_token,
          is_login: true,
        };
        result = await signInWithGoogle(body);
        if (result?.status !== 200) {
          messageError =
            result?.status === 400
              ? "This Google account is already associated with an existing account in TIA app."
              : result?.exception || "An error occurred during Google sign-in.";
        }
      } else {
        const { email, password } = this.state;
        !Boolean(email) ? this.setState({ emailRequired: true }) : null;
        !Boolean(password) ? this.setState({ passwordRequired: true }) : null;

        if (!email || !password) {
          return showMessage({
            message: "Email or Password can't be empty.",
            type: "info",
            color: "#FFF",
            backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
            duration: 4000,
          });
        }

        const body = {
          email,
          password,
          auth_provider: "email",
        };

        result = await signInAccount(body);
        if (result?.status !== 200) messageError = "Email or password isn't correct."
      }

      if (result?.status === 200 && result?.data) {
        const { token, ...passFeild } = result.data;

        await AsyncStorage.setItem("access", JSON.stringify(token.access));
        await AsyncStorage.setItem("refresh", JSON.stringify(token.refresh));

        this.props.setClient({ ...passFeild });

        await new Promise((resolve) => {
          setTimeout(() => {
            this.props.navigation.navigate("MainScreen");
            return resolve([]);
          }, 100);
        });
      } else {
        return showMessage({
          message: messageError || "Login failed.",
          type: "info",
          color: "#FFF",
          backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
          duration: 4000,
        });
      }
    } catch (error) {
      this.setState({ signing: false });
      showMessage({
        message: error?.message || "Login failed.",
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
    } finally {
      this.setState({ isLoading: false });
      this.setState({ signing: false });
    }
  };

  render() {
    const { isPC, isFocus, signing, responseToken } = this.state;
    return (
      <SafeAreaView
        style={styles.container}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <AuthBanner />
        <View
          onPress={Keyboard.dismiss}
          accessible={false}
          style={{
            width: isPC ? "50%" : "100%",
            backgroundColor: MAIN_COLORS.BACKGROUND_BLACK,
          }}>
          <View style={styles.boxLogoTIA} />
          <View style={[styles.scrollViewContentView, styles.sectionContent]}>
            <View>
              <Text style={styles.sectionTitle}>Hey! Register here</Text>
              <Text style={styles.sectionSubTitle}>
                Please register here to continue!
              </Text>
            </View>

            <View style={styles.sectionCenterView}>
              <View style={styles.sectionForm}>
                <Text style={styles.labelForm}>Email</Text>
                <div id="input_email">
                  <TextInput
                    ref={(input) => (this.emailTextInput = input)}
                    keyboardType="web-search"
                    autoCorrect={false}
                    style={[
                      styles.inputForm,
                      { borderColor: isFocus === "email" ? "#fff" : "#34393D" },
                    ]}
                    placeholderTextColor="#ADB9C7"
                    value={this.state.email}
                    underlineColorAndroid="transparent"
                    placeholder="Enter Your Email"
                    returnKeyType={"next"}
                    blurOnSubmit={false}
                    autoCapitalize="none"
                    onFocus={() => this.setState({ isFocus: "email" })}
                    onBlur={() => this.setState({ isFocus: null })}
                    onSubmitEditing={() => this.passwordTextInput.focus()}
                    onChangeText={(email) => {
                      this.setState({ email, emailRequired: false });
                    }}
                  />
                </div>
              </View>

              <View style={styles.sectionForm}>
                <Text style={styles.labelForm}>Password</Text>
                <View
                  style={[
                    styles.inputForm,
                    styles.rowCenter,
                    {
                      borderColor: isFocus === "password" ? "#fff" : "#34393D",
                    },
                  ]}>
                  <div id="input_password" style={{ flex: 1 }}>
                    <TextInput
                      autoCorrect={false}
                      keyboardType="default"
                      returnKeyType={"done"}
                      value={this.state.password}
                      style={styles.inputPassword}
                      placeholderTextColor="#ADB9C7"
                      placeholder="Enter your password"
                      underlineColorAndroid="transparent"
                      onSubmitEditing={this.onLogin}
                      onFocus={() => this.setState({ isFocus: "password" })}
                      onBlur={() => this.setState({ isFocus: null })}
                      secureTextEntry={!this.state.showPassword}
                      ref={(input) => (this.passwordTextInput = input)}
                      onChangeText={(password) =>
                        this.setState({ password, passwordRequired: false })
                      }
                    />
                  </div>

                  <TouchableOpacity
                    onPress={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }>
                    <Image
                      style={{ height: 20, width: 20, resizeMode: "contain" }}
                      source={
                        this.state.showPassword
                          ? require("../../assets/eye.svg")
                          : require("../../assets/eye-slash.svg")
                      }
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}>
                <TouchableOpacity
                  onPress={() =>
                    this.props.navigation.navigate("ForgotPasswordScreen", {
                      type: "forgot",
                    })
                  }>
                  <Text
                    style={{
                      marginLeft: 4,
                      fontSize: 12,
                      fontFamily: MAIN_FONT.regular,
                      fontWeight: "400",
                      color: MAIN_COLORS.PRIMARY_COLOR,
                    }}>
                    Forgot Password?
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            <View>
              {/* TouchableOpacity Login */}
              <div id="btn_sign_in">
                <TouchableOpacity
                  style={[stylesCommonComponents.button, styles.btnSubmit]}
                  onPress={() => this.onLogin()}>
                  {signing && responseToken ? (
                    <MaterialIndicator
                      size={RFValue(16.75)}
                      color={MAIN_COLORS.BACKGROUND_WHITE}
                    />
                  ) : (
                    <Text style={styles.textButton}>Sign In</Text>
                  )}
                </TouchableOpacity>
              </div>

              {/* Divider */}
              {/* <View
                style={{
                  flexDirection: "row",
                  marginVertical: 24,
                  width: "60%",
                  alignSelf: "center",
                  alignItems: "center",
                }}>
                <View
                  style={{ flex: 1, height: 1, backgroundColor: "#34393D" }}
                />
                <Text
                  style={{
                    marginHorizontal: 16,
                    fontSize: 12,
                    color: MAIN_COLORS.G600,
                    fontFamily: MAIN_FONT.regular,
                    fontWeight: "400",
                  }}>
                  Or sign in with
                </Text>
                <View
                  style={{ flex: 1, height: 1, backgroundColor: "#34393D" }}
                />
              </View> */}

              {/* Social loggin */}
              {/* <View style={{ flexDirection: "column", gap: 16 }}>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  <ButtonLoginWithSocial
                    type="google"
                    onLogin={() => {
                      document.querySelector("#button-label").click();
                    }}
                  />
                  <View style={{ display: "none" }}>
                    <GoogleLogin
                      locale="en_US"
                      onSuccess={(res) => this.onLogin(res, "google")}
                      useOneTap={false}
                      auto_select={false}
                      theme="outline"
                    />
                  </View>
                </GoogleOAuthProvider>

                <FacebookLogin
                  appId={FACEBOOK_CLIENT_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  scope="email,public_profile"
                  callback={(res) => this.onLogin(res, "facebook")}
                  render={(renderProps) => (
                    <ButtonLoginWithSocial
                      type="facebook"
                      onLogin={renderProps.onClick}
                    />
                  )}
                />
              </View> */}
            </View>

            {/* Signup */}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
              }}>
              <Text style={styles.sectionSubTitle}>
                Don't have an account?{" "}
              </Text>
              <TouchableOpacity
                onPress={() =>
                  this.props.navigation.navigate("RegisterScreen", {
                    type: "create",
                  })
                }>
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: MAIN_FONT.regular,
                    fontWeight: "400",
                    color: MAIN_COLORS.PRIMARY_COLOR,
                  }}>
                  Sign Up
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        {this.state.isLoading ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
}

const ButtonLoginWithSocial = ({ type, onLogin }) => {
  let prefix;
  if (type === "facebook") prefix = require("../../assets/facebookLogo.png");
  if (type === "google") prefix = require("../../assets/googleLogo.png");

  return (
    <TouchableOpacity style={styles.containerButtonSocial} onPress={onLogin}>
      <Image source={prefix} style={{ width: 20, height: 20 }} />
      <Text style={styles.textButtonSocial}>
        Sign in with {type?.replace(/^\w/, (c) => c.toUpperCase())}
      </Text>
    </TouchableOpacity>
  );
};

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    CART: state.CART,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setClient,
      reset,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
