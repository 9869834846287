import { MAIN_COLOR, MAIN_COLORS } from "./Colors";
import { MAIN_FONT } from "./Fonts/font";
import { getProperty } from "../Clickup/api";

export const ORIGIN = `${window.location.origin}/client_portal`;
export const TEST_SUBURB = "Rockmore";
export const TEST_STATE = "QLD";
export const TEST_SUBURB_REAL = "Kirkwood";
export const GOOGLE_CLIENT_ID =
  "768442873006-47q99ljp6jjqelvfapu4e5rskglefkoc.apps.googleusercontent.com";
export const SCOPE_GOOGLE = "https://www.googleapis.com/auth/calendar";
export const FACEBOOK_CLIENT_ID = "1746152999283762";
export const formatDollar = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
    .format(Number(value || 0))
    .replace(/(\.|,)00$/g, "");
};

export const formatUnit = (value) => {
  return new Intl.NumberFormat("en-US").format(value);
};

export const formatDollarUnit = (value) => {
  const number = Number(value || 0);

  if (number >= 1000000 || number <= -1000000)
    return `$${(number / 1000000).toFixed(2)}M`;
  else if (number >= 1000 || number <= -1000)
    return `$${(number / 1000).toFixed(2)}K`;
  else
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(number)
      .replace(/(\.|,)00$/g, "");
};

export const capitalizeWords = (str) => {
  const cleanedString = str.replace(/\n+/g, " ");
  const words = cleanedString.trim().split(" ");

  if (words.length === 1) {
    return cleanedString.toUpperCase();
  }
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.borderRadius = "5px";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.padding = "8px";
    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  tooltipEl.style.background =
    chart?.tooltip?.labelColors?.[0]?.backgroundColor ??
    chart?.tooltip?.labelColors?.[0]?.borderColor ??
    MAIN_COLOR.yellow;

  return tooltipEl;
};

export const externalTooltipHandler = (context, padding, radius) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;
      td.style.fontWeight = 600;
      td.style.fontSize = "10px";
      td.style.lineHeight = "12px";
      td.style.fontFamily = MAIN_FONT.regular;
      td.style.color = MAIN_COLORS.TEXT_DARK;

      const text = document.createTextNode(body);

      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY - 50 + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = padding
    ? padding
    : tooltip.options.padding + "px " + tooltip.options.padding + "px";
  if (radius) tooltipEl.style.borderRadius = radius;
};

export const externalTooltipHandlerNew = (
  context,
  padding,
  radius,
  backgroudColor
) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;
      td.style.fontWeight = 600;
      td.style.fontSize = "10px";
      td.style.lineHeight = "12px";
      td.style.fontFamily = MAIN_FONT.regular;
      td.style.color = MAIN_COLORS.TEXT_DARK;
      td.lineHeight = 1;

      const text = document.createTextNode(body);

      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  let tooltipLeft = 0;
  const toolTipWidth = tooltip.width;
  const xAxisWidth = chart.scales.x.width;

  const isFirstPoint = tooltip.caretX - toolTipWidth / 2 < 0;
  const isLastPoint = tooltip.caretX + toolTipWidth / 2 > xAxisWidth;

  if (isFirstPoint) {
    tooltipLeft = 18;
  } else if (isLastPoint) {
    tooltipLeft = -18;
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + tooltipLeft + "px";
  tooltipEl.style.top =
    positionY -
    25 -
    (padding?.top ?? 0) -
    (padding?.bottom ?? 0) +
    tooltip.caretY +
    "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.backgroundColor = backgroudColor || MAIN_COLORS.PRIMARY_COLOR;
  tooltipEl.style.display = "flex";
  tooltipEl.style.alignItem = "center";
  tooltipEl.style.justifyContent = "center";
  tooltipEl.style.width = "max-content";
  tooltipEl.style.maxHeight = 16 + "px";
  tooltipEl.style.paddingLeft = padding.left + "px";
  tooltipEl.style.paddingRight = padding.right + "px";
  tooltipEl.style.paddingTop = padding.top + "px";
  tooltipEl.style.paddingBottom = padding.bottom + "px";

  if (radius) tooltipEl.style.borderRadius = radius;
};

export const externalTooltipBarHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = 0;

      const th = document.createElement("th");
      th.style.borderWidth = 0;
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = 0;

      const td = document.createElement("td");
      td.style.borderWidth = 0;
      td.style.fontWeight = 600;
      td.style.fontSize = "10px";
      td.style.lineHeight = "12px";
      td.style.fontFamily = MAIN_FONT.regular;
      td.style.color = MAIN_COLOR.BACKGROUND_COLOR_BLACK;
      td.style.lineHeight = 1;

      const text = document.createTextNode(body);

      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 0.8;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.paddingLeft = 4 + "px";
  tooltipEl.style.paddingRight = 4 + "px";
  tooltipEl.style.paddingTop = 4 + "px";
  tooltipEl.style.zIndex = 99;
  tooltipEl.style.width = "max-content";

  const heightTopBar = 40;
  const yAxisHeight = chart.scales.y.height;
  const xAxisWidth = chart.scales.x.width;
  const toolTipWidth = tooltip.width;
  let tooltipLeft = positionX + tooltip.caretX + "px";
  let tooltipRight = "";
  let transform = "translate(-50%, 0px)";

  const isLastPoint = tooltip.caretX + toolTipWidth / 2 > xAxisWidth;
  const isFirstPoint = tooltip.caretX - toolTipWidth / 2 < 0;

  if (isLastPoint) {
    tooltipRight = "14px";
    tooltipLeft = "";
    transform = "";
  } else if (isFirstPoint) {
    tooltipLeft = "14px";
    tooltipRight = "";
    transform = "";
  } else if (tooltip.caretX > xAxisWidth) {
    tooltipLeft = tooltip.caretX - toolTipWidth / 2 + "px";
    tooltipRight = "";
    transform = "translate(-50%, 0px)";
  } else if (tooltip.caretX < toolTipWidth) {
    tooltipLeft = toolTipWidth / 2 + 4 + "px";
    tooltipRight = "";
    transform = "translate(-50%, 0px)";
  }

  tooltipEl.style.left = tooltipLeft;
  tooltipEl.style.right = tooltipRight;
  tooltipEl.style.transform = transform;
  tooltipEl.style.top = tooltip.caretY - heightTopBar + "px";
  tooltipEl.style.paddingBottom = yAxisHeight - tooltip.caretY + 16 + "px";
};

export const monthDiff = (start, end) => {
  let months = 0;
  months = (end.getFullYear() - start.getFullYear()) * 12;
  months -= start.getMonth();
  months += end.getMonth();
  return months <= 0 ? 0 : months;
};

export const sumArrays = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0)
  );
};

export const fetchPropertyData = async (id) => {
  try {
    const propertyData = await getProperty(id);
    if (propertyData?.err) return null;
    return {
      ...propertyData?.custom_fields?.reduce(
        (fields, field) => ({
          ...fields,
          [field.name]: field.value,
        }),
        {}
      ),
      id: propertyData.id,
      date_updated: propertyData.date_updated,
    };
  } catch (error) {
    return null;
  }
};
