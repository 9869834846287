import fetchIntercept from "fetch-intercept";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { logout } from "../Action/navigateCtrl";
import { refreshToken } from "../Clickup/api";
import { store as storeRoot } from "../Reducer";
import { reset } from "../Reducer/tia";

const privateApiEndpoints = [
  "users_tia/logout",
  "users_tia/get-profile",
  "users_tia/change-password",
  "users_tia/change-profile",
];

const unregister = fetchIntercept.register({
  request: async (url, config) => {
    let access;
    const string_access = await AsyncStorage.getItem("access");
    if (string_access && string_access !== "undefined")
      access = JSON.parse(string_access);

    const isPrivateApi = privateApiEndpoints.some((api) => url.includes(api));
    if (isPrivateApi) {
      if (!access) {
        await AsyncStorage.removeItem("access");
        throw new Error("No access token");
      }
      config.headers.set("Authorization", `Bearer ${access}`);
    }

    return [url, config];
  },

  response: async (response) => {
    let store, refresh;
    const string_store = await AsyncStorage.getItem("persist:root");
    const string_refresh = await AsyncStorage.getItem("refresh");

    if (string_store) store = JSON.parse(string_store);
    if (string_refresh) refresh = JSON.parse(string_refresh);

    const client = store ? JSON.parse(store?.TIA)?.client : null;

    if (response.status === 401) {
      if (response.url.includes("users_tia/token/refresh")) {
        storeRoot.dispatch(reset());
        await AsyncStorage.removeItem("access");
        await AsyncStorage.removeItem("refresh");
        return logout();
      }

      if(response.url.includes("portfolio/detail-scenario")){
        return response
      }

      const newTokens = await refreshToken(client?.email, refresh);

      await AsyncStorage.setItem("access", JSON.stringify(newTokens.access));

      // retry api
      const { url, request } = response;
      const body = request?._bodyInit || request?.bodyText;

      if (body) request.body = body;

      const retryResponse = await fetch(url, request);
      return retryResponse;
    }

    return response;
  },

  requestError: function (error) {
    console.log("request Error", error);
    return Promise.reject(error);
  },

  responseError: function (error) {
    console.log("response Error", error);
    return Promise.reject(error);
  },
});

export default unregister;
