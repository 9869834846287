import axios from "axios";
import GoogleMapReact from "google-map-react";
import React, { memo, useEffect, useState } from "react";
import { View, StyleSheet, Image, Text, TouchableOpacity } from "react-native";

import { format } from "../../../Zoho/Tasks";
import { MAIN_COLORS } from "../../../Utility/Colors";
import { formatDollar } from "../../../Utility/common";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../../Utility/Fonts/font";

const Details = ({ property, openUrl, onBookCallClick }) => {
  const apiKey = "AIzaSyCxTNgttStL2DDlLN9VetA2-9es8jD9pSg";
  const address =
    property["PROPERTY STREET ADDRESS"] +
    " " +
    property["PROPERTY SUBURB"] +
    " " +
    property["PROPERTY STATE"] +
    " " +
    property["PROPERTY POSTCODE"];
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json`,
          {
            params: {
              address: address,
              key: apiKey,
            },
          }
        );

        if (response.status === 200) {
          const _location = response.data.results[0].geometry.location;
          setLocation(_location);
        }
      } catch (error) {}
    };
    address && fetch();
  }, [address]);

  return (
    <View style={styles.container}>
      <View style={styles.propertyImageBox}>
        <Image
          style={styles.propertyImage}
          source={{ uri: property["PROPERTY IMAGE URL"] }}
        />
        <View style={styles.propertyProps}>
          <View style={styles.propertyProp}>
            <Image
              style={styles.propertyPropImage}
              source={require("../../../assets/home/bed.svg")}
            />
            <Text style={styles.propertyPropText}>
              {property["NO. OF BEDROOMS"]} Beds
            </Text>
          </View>

          <View style={styles.propertyProp}>
            <Image
              style={styles.propertyPropImage}
              source={require("../../../assets/home/bath.svg")}
            />
            <Text style={styles.propertyPropText}>
              {property["NO. OF BATHROOMS"]} Baths
            </Text>
          </View>

          <View style={styles.propertyProp}>
            <Image
              style={styles.propertyPropImage}
              source={require("../../../assets/home/car.svg")}
            />
            <Text style={styles.propertyPropText}>
              {property["# OF PARKING"]} Cars
            </Text>
          </View>

          <View style={styles.propertyProp}>
            <Image
              style={styles.propertyPropImage}
              source={require("../../../assets/home/size.svg")}
            />
            <Text style={styles.propertyPropText}>
              {property["AREA (M2)"]}m2
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.propertyInfo}>
        <View style={[styles.rowCenterBetween, { alignItems: "flex-start" }]}>
          <View>
            <Text style={styles.sectionTitle}>
              {property["PROPERTY STREET ADDRESS"]}
            </Text>
            <View style={{ flexDirection: "row", gap: 6 }}>
              <Image
                style={{ width: 20, height: 20 }}
                source={require("../../../assets/home/location.svg")}
              />
              <Text style={styles.sectionText}>
                {property["PROPERTY SUBURB"]}, {property["PROPERTY STATE"]}{" "}
                {property["PROPERTY POSTCODE"]}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              gap: 12,
            }}
          >
            <TouchableOpacity
              onPress={onBookCallClick}
              style={styles.btnBooking}
            >
              <Text style={styles.textBtnBooking}>Book a Call</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.btnViewProperty}
              onPress={() => openUrl(property["PROPERTY URL"])}
            >
              <Image
                style={{ width: 18, height: 18 }}
                source={require("../../../assets/Light/house.svg")}
              />
              <Text style={styles.textBtbViewProperty}>View Property</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={[styles.propertyInfo, { flexDirection: "row" }]}>
          <View style={[styles.sectionContent, { gap: 16 }]}>
            <Text style={styles.sectionTitleSmall}>Property Details</Text>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.sectionText}>Asking Price</Text>
              <Text style={styles.sectionValue}>
                {formatDollar(property["ASKING PRICE $"])}
              </Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.sectionText}>Target Price</Text>
              <Text style={styles.sectionValue}>
                {formatDollar(property["TARGET PRICE"])}
              </Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.sectionText}>TIA Property Valuation</Text>
              <Text
                style={[
                  styles.sectionValue,
                  { color: MAIN_COLORS.PRIMARY_COLOR },
                ]}
              >
                {formatDollar(property["TIA Property Valuation"])}
              </Text>
            </View>
            <View style={styles.divider} />
            <View style={styles.rowCenterBetween}>
              <Text style={styles.sectionText}>Potential Rent</Text>
              <Text style={styles.sectionValue}>
                {formatDollar(property["APPRAISED RENTAL RATE P/W $"])}
              </Text>
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.sectionText}>Potential Gross Yield</Text>
              <Text style={styles.sectionValue}>
                {format(
                  ((Number(property["APPRAISED RENTAL RATE P/W $"]) * 52) /
                    Number(property["TARGET PRICE"])) *
                    100
                )}{" "}
                %
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.sectionContent,
              { padding: 0, position: "relative" },
            ]}
          >
            {location && (
              <>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: apiKey }}
                  defaultCenter={location}
                  defaultZoom={18}
                  options={{
                    mapTypeId: "satellite",
                    gestureHandling: "none",
                    fullscreenControl: false,
                  }}
                >
                  <Marker lat={location.lat} lng={location.lng} />
                </GoogleMapReact>
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

const Marker = () => {
  return (
    <div
      style={{
        position: "relative",
        transition: "all 0.8s",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img
        src={require("../../../assets/home/dot_map.svg")}
        alt="default marker"
        style={{ width: 24, height: 24 }}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  propertyImageBox: {
    flex: 1,
    position: "relative",
    overflow: "hidden",
    borderRadius: 10,
  },

  propertyImage: {
    width: "100%",
    aspectRatio: 600 / 320,
    transform: [{ scale: 1.5 }],
    resizeMode: "contain",
  },

  propertyProps: {
    position: "absolute",
    width: "max-content",
    flexDirection: "row",
    gap: 12,
    left: "50%",
    transform: [{ translateX: "-50%" }],
    bottom: 16,
  },

  propertyProp: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    backgroundColor: "#19232BD9",
  },

  propertyPropImage: {
    width: 24,
    height: 24,
  },

  propertyPropText: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.medium,
    lightHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  propertyInfo: {
    flex: 1,
    display: "flex",
    gap: 17,
  },

  sectionContent: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    padding: 16,
    borderWidth: 1,
    borderColor: "#4B555D99",
    overflow: "hidden",
  },

  sectionTitleSmall: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  rowCenterBetween: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  sectionText: {
    fontWeight: FONT_WEIGHT.medium,
    lineHeight: 21,
    fontSize: FONT_SIZE.medium,
    color: MAIN_COLORS.TEXT_GRAY,
    fontFamily: MAIN_FONT.regular,
  },

  sectionValue: {
    fontSize: FONT_SIZE.medium,
    lineHeight: 21,
    fontWeight: FONT_WEIGHT.medium,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.regular,
  },

  btnViewProperty: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 6,
    paddingVertical: 8,
    paddingHorizontal: 24,
    borderRadius: 6,
    borderWidth: 1.5,
    borderColor: MAIN_COLORS.PRIMARY_COLOR,
  },

  textBtbViewProperty: {
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 21,
    fontSize: FONT_SIZE.medium,
    color: "#D3B100",
    fontFamily: MAIN_FONT.semi,
  },

  sectionTitle: {
    fontSize: FONT_SIZE.large,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 24,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },

  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#4B555D99",
  },

  btnBooking: {
    backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
    paddingVertical: 10,
    paddingHorizontal: 24,
    borderRadius: 6,
    height: 42,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },

  textBtnBooking: {
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 21,
    fontSize: FONT_SIZE.medium,
    color: "#19232B",
    fontFamily: MAIN_FONT.semi,
  },
});

export default memo(Details);
