import moment from "moment";
import { BlurView } from "expo-blur";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import * as Progress from "react-native-progress";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  Text,
  ImageBackground,
} from "react-native";

import { MAIN_COLOR, MAIN_COLORS } from "../Utility/Colors";
import { MAIN_FONT } from "../Utility/Fonts/font";

import Time from "./Time";

const OpportunityItem = ({ item, style, onPress }) => {
  const { template } = useSelector((state) => state.TIA);

  const [widthProgress, setWidthProgress] = useState(150);

  let stage = item?.status?.status?.toUpperCase();
  let allStages = Object.keys(template);
  let progress = 0;
  let stageIndex = allStages.indexOf(stage);
  if (stageIndex < 1) progress = 0;
  else progress = (stageIndex * 100) / (allStages.length - 1);

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <View style={styles.topLeftSection}>
        <BlurView intensity={10} style={styles.blur}>
          <Image
            resizeMode="contain"
            style={{
              width: 24,
              height: 24,
              tintColor: MAIN_COLORS.G800,
            }}
            source={
              item?.purchase_by
                ? require("../assets/portfolio-roadmap/house_option2.png")
                : require("../assets/portfolio-roadmap/house_option.png")
            }
          />
        </BlurView>
      </View>
      <View style={styles.topRightSection}>
        <BlurView intensity={10} style={styles.blur} />
        <Text style={styles.txtPurchaseBy}>Purchased by</Text>
        <Text style={styles.txtAgency}>
          {item?.purchase_by ? "Independently" : "The Investors Agency"}
        </Text>
      </View>
      {item?.co_labeling_logo ? (
        <View style={styles.attached}>
          <Image
            style={{
              width: 60,
              height: 30,
            }}
            resizeMode="contain"
            source={item?.co_labeling_logo}
          />
        </View>
      ) : null}
      <ImageBackground
        style={{
          width: "100%",
          aspectRatio: 380 / 300,
          backgroundColor: "#222C33",
          transform: [{ scale: item?.purchase_connected_image ? 2 : 1 }],
        }}
        source={{ uri: item?.purchase_connected_image }}
      >
        {!item?.purchase_connected_image && (
          <Image
            style={{
              width: 300,
              height: 150,
              marginHorizontal: "auto",
              marginTop: 20,
            }}
            source={require("../assets/home/no_available.svg")}
            resizeMode="contain"
          />
        )}
      </ImageBackground>
      <View style={styles.info}>
        <BlurView intensity={10} style={styles.blurInfo}>
          <View style={styles.infoTop}>
            <View style={{ flexDirection: "row", gap: 6 }}>
              <Image
                style={styles.icon}
                source={require("../assets/home/location.svg")}
              />
              {item?.purchase_connected_address && (
                <Text style={styles.portfolioName} numberOfLines={2}>
                  {item.purchase_connected_address}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.infoBottom}>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.text}>Update: </Text>
              <Time date={item?.date_updated * 1} />
            </View>
            <View style={styles.rowCenterBetween}>
              <Text style={styles.text}>Progress: </Text>
              <View
                style={{ flex: 1 }}
                onLayout={(event) => {
                  const { width } = event.nativeEvent.layout;
                  setWidthProgress(width);
                }}
              >
                <Progress.Bar
                  progress={progress / 100}
                  animationType="spring"
                  width={widthProgress}
                  height={6}
                  borderRadius={54}
                  borderWidth={0}
                  color="#D3B100"
                  unfilledColor={"#E7ECF2"}
                />
              </View>
              <Text style={styles.textProgress}>
                {progress.toFixed(0) || 0}%
              </Text>
            </View>
          </View>
          <View></View>
        </BlurView>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    minWidth: 380,
    minHeight: 300,
    borderRadius: 12,
    backgroundColor: MAIN_COLOR.BACKGROUND_COLOR_MAIN,
    overflow: "hidden",
  },

  topLeftSection: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    position: "absolute",
    top: 10,
    left: 10,
    width: 36,
    height: 36,
    zIndex: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  topRightSection: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    borderRadius: 5,
    paddingHorizontal: 6,
    paddingVertical: 5,
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  txtPurchaseBy: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 8,
    fontWeight: 400,
    color: MAIN_COLORS.G800,
    lineHeight: "120%",
  },
  txtAgency: {
    fontFamily: MAIN_FONT.regular,
    fontSize: 10,
    fontWeight: 600,
    color: MAIN_COLORS.G800,
    lineHeight: "120%",
  },
  blur: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    zIndex: -1,
  },

  attached: {
    width: 66,
    height: 33,
    borderRadius: 4,
    backgroundColor: "#fff",
    position: "absolute",
    top: 50,
    right: 10,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4,
    elevation: 2,
  },

  info: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "auto",
    backgroundColor: "#19232BD9",
  },
  infoTop: { marginBottom: 8 },
  infoBottom: { display: "flex", gap: 6 },
  blurInfo: {
    width: "100%",
    height: "100%",
    paddingHorizontal: 14,
    paddingVertical: 10,
  },
  portfolioName: {
    fontSize: 16,
    lineHeight: 24,
    fontWeight: 600,
    color: MAIN_COLOR.BACKGROUND_COLOR,
    marginBottom: 4,
    fontFamily: MAIN_FONT.semi,
  },
  text: {
    fontSize: 12,
    lineHeight: 18,
    fontWeight: 400,
    color: "#C8C8C8",
    fontFamily: MAIN_FONT.regular,
  },

  icon: {
    width: 18,
    height: 18,
    marginTop: 3,
  },

  rowCenterBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 6,
  },
  divider: {
    width: 2,
    height: 12,
    backgroundColor: "#C8C8C8",
    borderRadius: 21,
  },

  textProgress: {
    color: "#D3B100",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 22,
    fontFamily: MAIN_FONT.regular,
  },
});

export default memo(OpportunityItem);
