import React from "react";
import moment from "moment";
import "@firebase/firestore";
import firebase from "firebase";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showMessage } from "react-native-flash-message";
import { Platform, SafeAreaView } from "react-native";
import _ from "lodash";

//Function
import { ROLES } from "../../hooks/usePermission";
import { MAIN_COLORS } from "../../Utility/Colors";
import { user as setUser } from "../../Reducer/user";
import { setShowCalendar } from "../../Reducer/portfolio-roadmap";
import {
  getBrandingByLeadSourceValue,
  getLeads,
  getProfileAccount,
  getTIATeamForApp,
} from "../../Clickup/api";
import {
  setLoader,
  setOpenDetails,
  setCurrentScreen,
  setOpportunities,
  setNotifications,
  setNotificationsObject,
  setTeamTIAData,
  setClient,
  setDeal,
  setPropertyIds,
  setChecklist,
  setClickUpStatusList,
} from "../../Reducer/tia";
import { ORIGIN, fetchPropertyData } from "../../Utility/common";

//Component
import Portfolio from "../Folio/index.v2";
import HomeComp from "./components/index.v2";
import { Panel } from "../../Layout/components/Panel";
import ProjectDetails from "../ProjectDetails/index.v2";
import LOADER from "../../Utility/ComponentCard/Loader";
import NotificationsScreen from "../Notifications/indexv2";
import PortfolioRoadmap from "../PortfolioRoadmap/index.v2";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.getOpportunities = this.getOpportunities.bind(this);
    this.state = {
      markedDates: {},
      refreshing: false,
      currentScreen:
        this.props.TIA.role === "Partner"
          ? "Roadmap"
          : this.props.TIA.currentScreen,
      email: this.props.TIA.client.email,
      leads: [],
      teamTIAData: [],
      mountInHome: false,
      notificationIndex: 0,
      deal: null,
    };
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProp, prevState) {
    if (prevState.currentScreen !== this.state.currentScreen)
      this.props.setCurrentScreen(this.state.currentScreen);

    if (
      prevState.currentScreen !== "Home" &&
      this.state.currentScreen === "Home"
    ) {
      if (!this.state.mountInHome) {
        this.props.setLoader(true);
        this.refresh();
      }
    }

    if (prevProp.TIA.currentScreen !== this.props.TIA.currentScreen) {
      this.setState({ currentScreen: this.props.TIA.currentScreen });
      this.props.setShowCalendar(true);
    }
  }

  async getNotifications() {
    var db = firebase.firestore();
    let allLeadsId = "d22875df-8bfe-430f-8ab9-c33736223f47";
    let leads = this.props.TIA.client.custom_fields?.filter(
      (item) => item.id === allLeadsId
    );
    let leadsIds = {};

    if (leads?.[0]?.value) {
      leads?.[0]?.value.map((item) => (leadsIds[item.id] = true));
    }

    db.collection("notifications")
      .get()
      .then((querySnapshot) => {
        let arr = [];
        querySnapshot.forEach((doc) => {
          if (leadsIds[doc.id]) {
            this.props.setNotificationsObject({ id: doc.id, data: doc.data() });
            arr = arr.concat(Object.values(doc.data()));
          }
          arr.sort(function (a, b) {
            return (
              new Date(b.dateUpdated.seconds * 1000) -
              new Date(a.dateUpdated.seconds * 1000)
            );
          });
        });
        this.getMarkedDates(arr);
        this.props.setNotifications(arr);

        // db.collection("notifications").onSnapshot((querySnapshot) => {
        //   arr = [];
        //   querySnapshot.forEach((doc) => {
        //     if (leadsIds[doc.id]) {
        //       this.props.setNotificationsObject({
        //         id: doc.id,
        //         data: doc.data(),
        //       });
        //       arr = arr.concat(Object.values(doc.data()));
        //     }
        //   });
        //   arr.sort(function (a, b) {
        //     return (
        //       new Date(b.dateUpdated.seconds * 1000) -
        //       new Date(a.dateUpdated.seconds * 1000)
        //     );
        //   });
        //   this.props.setNotifications(arr);
        //   this.getMarkedDates(arr);
        //
        // });
      })
      .catch((error) => {
        alert(error.message);
      });
  }

  async getOpportunities(value) {
    try {
      if (!value) return this.props.setLoader(false);
      // const oldOpportunities = this.props.TIA?.opportunities;

      const checklists = [];
      const opportunities = [];
      const clickUpStatusList = [];

      // const opportunititySelect = oldOpportunities
      //   .map((opp) => (_.some(value, { id: opp.id }) ? opp : null))
      //   .filter(Boolean);
      // if (opportunititySelect.length === value.length) {
      //   for (const lead of opportunititySelect) {
      //     checklists.push(...lead.checklists);
      //     clickUpStatusList.push({
      //       taskId: lead?.id,
      //       status: lead?.status?.status ?? "",
      //     });
      //     opportunities.push(lead);
      //   }
      // } else {
      const promises = value.map((item) => getLeads(item.id));
      const result = await Promise.all(promises);

      for (const lead of result) {
        checklists.push(...lead.checklists);
        clickUpStatusList.push({
          taskId: lead?.id,
          status: lead?.status?.status ?? "",
        });

        const opportunity = lead?.custom_fields?.reduce(
          (obj, item) => Object.assign(obj, { [item.name]: item.value }),
          {}
        );

        let purchase_connected_address;
        let purchase_connected_image;
        if (opportunity?.["Proposed Property"]?.length > 0) {
          const purchaseIdConnected = opportunity["Proposed Property"]?.[0]?.id;
          const purchase = await fetchPropertyData(purchaseIdConnected);

          const address = purchase?.["PROPERTY STREET ADDRESS"];
          const suburb = purchase?.["PROPERTY SUBURB"];
          const state = purchase?.["PROPERTY STATE"];
          const postcode = purchase?.["PROPERTY POSTCODE"];
          purchase_connected_address = `${address}, ${suburb} ${state} ${postcode}`;

          purchase_connected_image = purchase?.["Image IDs"]
            ? `https://insights.proptrack.com/imagery/350x265/${
                purchase["Image IDs"].split(",")[0].split(".")[0]
              }/image.jpg`
            : purchase?.["PROPERTY IMAGE URL"];
        }

        let co_labeling_logo;
        if (!!opportunity?.["Lead Source"]) {
          const response = await getBrandingByLeadSourceValue(
            opportunity["Lead Source"]
          );
          co_labeling_logo = response?.tasks?.[0]?.custom_fields?.find(
            (one) => one?.name === "Co-labeling logo"
          )?.value?.[0].url;
        }

        lead["custom_fields"] = null;
        opportunities.push({
          ...lead,
          ...opportunity,
          co_labeling_logo,
          purchase_connected_image,
          purchase_connected_address,
        });
      }

      this.props.setClickUpStatusList(clickUpStatusList);
      this.props.setOpportunities(opportunities);
      this.props.setChecklist(checklists);
      // }

      this.setState({ leads: opportunities });
      this.props.setLoader(false);
    } catch (error) {
      console.log(error.message);
    }
  }

  async fetchTIATeamData() {
    try {
      const data = await getTIATeamForApp();
      const teamTIAData = data?.tasks?.length
        ? data.tasks.map((task) => {
            const customFields = task.custom_fields.reduce((acc, field) => {
              if (field.name === "Phone") acc.phone = field.value;
              if (field.name === "Email") acc.email = field.value;
              if (field.name === "Type") acc.type = field.value;

              if (field.name === "Avatar" && Array.isArray(field.value)) {
                const urlObject = field.value.find((obj) => obj.url);
                acc.avatar = urlObject ? urlObject.url : null;
              }

              if (
                field.name === "Contact photo" &&
                Array.isArray(field.value)
              ) {
                const firstObject = field.value[0];
                acc.contact_photo = firstObject ? firstObject.url : null;
              }
              return acc;
            }, {});

            customFields.name = task.name;
            return customFields;
          })
        : [];

      this.setState({ teamTIAData });
      this.props.setTeamTIAData(teamTIAData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async fetchInfoUser() {
    try {
      const { client } = this.props.TIA;
      const result = await getProfileAccount(client?.id);

      if (result?.status === 200) {
        const { user_info, ...passField } = result.data;
        const {email, ...pass_info} = user_info
        return this.props.setClient({ ...passField, ...pass_info });
      }

      showMessage({
        message: "Loading profile info!" || result?.exception?.detail,
        type: "info",
        color: "#FFF",
        backgroundColor: MAIN_COLORS.PRIMARY_COLOR,
        duration: 4000,
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  async refresh() {
    await this.fetchInfoUser();

    this.fetchTIATeamData();
    this.getNotifications();

    const opportunities = this.props.TIA?.client?.custom_fields
      ?.find((feild) => feild?.name === "Opportunities")
      ?.value?.slice(0, 6);
    this.getOpportunities(opportunities);
  }

  componentDidMount() {
    const { role } = this.props.TIA;

    if (role.toLowerCase() === ROLES.CLIENT) {
      if (this.state.currentScreen === "Home") {
        this.props.setLoader(true);
        this.refresh();
        this.setState({ mountInHome: true });
      }
    } else {
      this.setState({ currentScreen: "Roadmap" });
    }

    this.props.setOpenDetails("");

    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (window.location.pathname === "/verify-email" && params.get("email")) {
      this.props.navigation.navigate("SignUpScreen");
    }
    if (
      window.location.pathname.indexOf("scenario-detail") !== -1 &&
      params.get("scenario_id")
    ) {
      this.setState({ currentScreen: "Roadmap" });
    }

    // window.history.replaceState({}, "", ORIGIN);
  }

  aggregateByDate(obj) {
    const result = {};

    for (const key in obj) {
      const date = obj[key].date;

      if (result[date]) result[date].quantity += 1;
      else result[date] = { ...obj[key], quantity: 1 };
    }

    return result;
  }

  getMarkedDates(notifications) {
    let markedDates = {};
    notifications.map((item, index) => {
      markedDates[index] = {
        taskId: item.taskId,
        statusName: item.statusName,
        date: moment(item.dateUpdated.seconds * 1000).format("YYYY-MM-DD"),
      };
    });

    this.setState({ markedDates: this.aggregateByDate(markedDates) });
  }

  render() {
    const { markedDates, leads } = this.state;

    const goToProjectDetails = (id, statusName) => {
      let arr = [
        "PROFILE REVIEW", // todo
        "SEARCH PROCESS",
        "MARKET SELECTION",
        "PROPERTY SELECTION",
        "PROPERTY WALK-THROUGH",
        "CONTRACT, B&P & PM",
        "BUILDING & PEST REPORTS",
        "UNCONDITIONAL",
        "SETTLED",
        "MARKET UPDATES",
      ];

      this.props.TIA.opportunities.map((item) => {
        if (item.id === id) {
          this.setState({
            currentScreen: "ProjectDetails",
            deal: item,
            notificationIndex: arr.indexOf(statusName),
          });
          this.props.setDeal(item);
        }
      });
    };

    return (
      <SafeAreaView
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: "#FFFFFF",
        }}
        keyboardShouldPersistTaps={true}
        behavior={Platform.OS === "ios" ? "padding" : "padding"}
        keyboardVerticalOffset={Platform.OS === "ios" ? -200 : 900}>
        <Panel
          screen={this.state.currentScreen}
          setPropScreen={this.props.setCurrentScreen}
          setStateScreen={(screen) => this.setState({ currentScreen: screen })}
          navigate={this.props.navigation.navigate}
        />

        {this.state.currentScreen === "Home" && (
          <HomeComp
            leads={leads}
            goToProjectDetails={goToProjectDetails}
            getOpportunities={this.getOpportunities}
            setStateScreen={(screen) =>
              this.setState({ currentScreen: screen })
            }
            setDeal={(deal) => {
              this.setState({ deal });
              this.props.setDeal(deal);
            }}
            markedDates={markedDates}
            navigation={this.navigation}
          />
        )}
        {this.state.currentScreen === "ProjectDetails" && (
          <ProjectDetails
            deal={this.state.deal ?? this.props?.TIA?.dealSelect}
            notificationIndex={this.state.notificationIndex}
            checklists={this.props.TIA.checklists}
            clickUpStatusList={this.props.TIA.clickUpStatusList}
            setStateScreen={(screen) =>
              this.setState({ currentScreen: screen })
            }
          />
        )}
        {this.state.currentScreen === "Portfolio" && <Portfolio />}
        {this.state.currentScreen === "Notifications" && (
          <NotificationsScreen
            goToProjectDetails={goToProjectDetails}
            markedDates={markedDates}
          />
        )}
        {this.state.currentScreen === "Roadmap" && <PortfolioRoadmap />}
        {this.props.TIA.loader ? <LOADER /> : null}
      </SafeAreaView>
    );
  }
}

function mapStateToProps(state) {
  return {
    COLORS: state.COLORS,
    USER: state.USER,
    TIA: state.TIA,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setOpportunities,
      setNotifications,
      setNotificationsObject,
      setLoader,
      setUser,
      setOpenDetails,
      setCurrentScreen,
      setShowCalendar,
      setTeamTIAData,
      setClient,
      setDeal,
      setPropertyIds,
      setChecklist,
      setClickUpStatusList,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
