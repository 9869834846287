import React from "react";
import { useSelector } from "react-redux";
import { View, StyleSheet, Image, Text, TouchableOpacity } from "react-native";

import fields from "../../Fields";
import { MAIN_COLORS } from "../../Colors";
import { FONT_SIZE, FONT_WEIGHT, MAIN_FONT } from "../../Fonts/font";

import Time from "../../../components/Time";

const NotiItem = ({ item, goToProjectDetails }) => {
  const { template } = useSelector((state) => state.TIA);
  const handleStatusName = (statusName) => {
    let keys = Object.keys(template);
    let index = keys.indexOf(statusName);

    return index > 0
    ? template[keys[index - 1]][fields.appStageName]
    : template[keys[index]][fields.appStageName];
  };

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        let keys = Object.keys(template);
        let index = keys.indexOf(item.statusName);
        const _statusName = index === 0 ? keys[index] : keys[index - 1]

        goToProjectDetails(item.taskId, _statusName)
      }}
    >
      <View style={styles.imageBox}>
        <Image
          style={[
            styles.image,
            { transform: [{ scale: item?.isConnected ? 2 : 1 }] },
          ]}
          source={
            item?.image
              ? { uri: item?.image }
              : item?.isConnected
              ? require("../../../assets/home/portfolio_image_default.png")
              : require("../../../assets/home/no_available.svg")
          }
        />
      </View>
      <View style={styles.centerView}>
        <Text style={styles.title}>
          {handleStatusName(item.statusName)}
        </Text>
        <Text style={styles.text}>{item.taskName}</Text>
        <View>
          <Time date={item.dateUpdated.seconds * 1000} />
        </View>
      </View>
      <Image
        style={{ width: 18, height: 18 }}
        source={require("../../../assets/home/options.svg")}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 12,
    borderRadius: 10,
    flexDirection: "row",
    gap: 12,
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
  },
  centerView: {
    flex: 1,
    display: "flex",
    gap: 4,
  },

  imageBox: {
    width: 46,
    height: 46,
    borderRadius: 6,
    overflow: "hidden",
  },

  image: {
    width: "100%",
    height: "100%",
    transform: [{ scale: 2 }],
  },

  rowCenterBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
  },

  text: {
    fontSize: FONT_SIZE.medium,
    lineHeight: 18,
    fontWeight: FONT_WEIGHT.medium,
    color: MAIN_COLORS.TEXT_GRAY,
    fontFamily: MAIN_FONT.regular,
  },

  title: {
    fontSize: FONT_SIZE.medium,
    fontWeight: FONT_WEIGHT.bold,
    lineHeight: 21,
    color: MAIN_COLORS.TEXT_LIGHT,
    fontFamily: MAIN_FONT.semi,
  },
});

export default NotiItem;
