import React, { memo, useEffect, useImperativeHandle, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Image,
  TextInput,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MAIN_COLORS } from "../../Utility/Colors";
import { MAIN_FONT } from "../../Utility/Fonts/font";

// Validation Schema
const schema = yup.object().shape({
  password: yup.string().required("Password is required"),
  newPassword: yup
    .string()
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,30}$/,
      "Password must be 8 to 30 characters and include at least one capital letter, one number and one special character."
    )
    .required("New password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const ChangePassword = ({
  formPassword,
  updateFormPassword,
  setIsFormValid,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid, setIsFormValid]);

  const handleChange = (field, value) => {
    updateFormPassword({ [field]: value });
  };

  const renderTextInput = ({
    field: { onChange, onBlur, value },
    placeholder,
    secureTextEntry,
    showPasswordToggle,
    setShowPasswordToggle,
    name,
  }) => (
    <View
      style={[
        styles.inputForm,
        styles.rowCenter,
        {
          borderColor: focusedInput === name ? "#fff" : "#34393D",
        },
      ]}>
      <TextInput
        type="password"
        autoComplete="new-password"
        autoCorrect={false}
        keyboardType="default"
        returnKeyType={"done"}
        value={value}
        style={styles.inputPassword}
        placeholderTextColor="#ADB9C7"
        placeholder={placeholder}
        underlineColorAndroid="transparent"
        onBlur={() => {
          onBlur();
          setFocusedInput(null);
        }}
        onFocus={() => setFocusedInput(name)}
        onChangeText={(text) => {
          onChange(text);
          handleChange(name, text);
        }}
        secureTextEntry={secureTextEntry}
      />
      <TouchableOpacity
        onPress={() => setShowPasswordToggle(!showPasswordToggle)}>
        <Image
          style={{ height: 20, width: 20, resizeMode: "contain" }}
          source={
            showPasswordToggle
              ? require("../../assets/eye-white.svg")
              : require("../../assets/eye-slash-white.svg")
          }
        />
      </TouchableOpacity>
    </View>
  );

  return (
    <View>
      <View style={styles.sectionForm}>
        <Text style={styles.labelForm}>Password</Text>
        <Controller
          control={control}
          name="password"
          render={(props) =>
            renderTextInput({
              ...props,
              placeholder: "Enter your password",
              secureTextEntry: !showPassword,
              showPasswordToggle: showPassword,
              setShowPasswordToggle: setShowPassword,
              name: "password",
            })
          }
        />
      </View>

      <View style={styles.sectionForm}>
        <Text style={styles.labelForm}>New Password</Text>
        <Controller
          control={control}
          name="newPassword"
          render={(props) =>
            renderTextInput({
              ...props,
              placeholder: "Enter your new password",
              secureTextEntry: !showNewPassword,
              showPasswordToggle: showNewPassword,
              setShowPasswordToggle: setShowNewPassword,
              name: "newPassword",
            })
          }
        />
      </View>

      <View style={styles.sectionForm}>
        <Text style={styles.labelForm}>Confirm Password</Text>
        <Controller
          control={control}
          name="confirmPassword"
          render={(props) =>
            renderTextInput({
              ...props,
              placeholder: "Enter your confirm password",
              secureTextEntry: !showConfirmPassword,
              showPasswordToggle: showConfirmPassword,
              setShowPasswordToggle: setShowConfirmPassword,
              name: "confirmPassword",
            })
          }
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  sectionForm: {
    marginBottom: 16,
  },
  labelForm: {
    lineHeight: 21,
    fontSize: 14,
    fontFamily: MAIN_FONT.regular,
    color: "#FFFFFF",
    marginBottom: 8,
  },
  inputForm: {
    height: 46,
    width: "100%",
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: MAIN_COLORS.BACKGROUND_MAIN,
    outlineStyle: "none",
  },
  rowCenter: {
    display: "flex",
    alignItems: "center",
  },
  inputPassword: {
    flex: 1,
    color: MAIN_COLORS.TEXT_LIGHT,
    outlineStyle: "none",
    fontSize: 14,
    fontWeight: 400,
    fontFamily: MAIN_FONT.regular,
    lineHeight: 21,
  },
});

export default memo(ChangePassword);
